import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classnames from 'classnames';
import { forwardRef } from 'react';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import { ICompiledContentType } from '../../../../../../contentInventory/content/models/CompiledContentType.ts';
import { NoElementsElement } from '../../../../../../itemEditor/features/ContentItemEditing/components/NoElementsElement.tsx';
import { ContentGroupTabsInComponent } from '../../../../../../itemEditor/features/ContentItemEditing/containers/ContentGroupTabsInComponent.tsx';
import { ContentNestingContextProvider } from '../../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { IContentComponent } from '../../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { getTypeElementsInContentGroup } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { ExpandedContentComponentElement } from './ExpandedContentComponentElement.tsx';

type Props = {
  readonly contentComponent: IContentComponent;
  readonly contentType: ICompiledContentType | null;
  readonly isHighlighted: boolean;
  readonly originalContentComponent?: IContentComponent;
  readonly selectedContentGroupId: Uuid | null;
};

export const ExpandedContentComponentElements = forwardRef<HTMLDivElement, Props>(
  (
    {
      contentComponent,
      contentType,
      isHighlighted,
      originalContentComponent,
      selectedContentGroupId,
    },
    ref,
  ) => {
    if (!contentType) {
      return <Loader ref={ref} />;
    }

    const contentTypeElements = contentType.contentElements;

    const elementsInGroup = getTypeElementsInContentGroup(
      contentTypeElements,
      selectedContentGroupId,
    );

    const expandedContentComponentElements = (
      <div className="expanded-content-component">
        {elementsInGroup.length === 0 ? (
          <NoElementsElement isDisabled />
        ) : (
          <ContentNestingContextProvider isCurrentLevelContentComponent>
            <ContentNestingContextProvider isCurrentLevelContentComponent>
              {elementsInGroup.map((currentTypeElement) => (
                <ExpandedContentComponentElement
                  key={currentTypeElement.elementId}
                  contentComponent={contentComponent}
                  currentTypeElement={currentTypeElement}
                  originalContentComponent={originalContentComponent}
                  typeElements={contentTypeElements}
                />
              ))}
            </ContentNestingContextProvider>
          </ContentNestingContextProvider>
        )}
      </div>
    );

    return (
      <div
        className={classnames('content-component__content', {
          'content-component__content--is-highlighted': isHighlighted,
        })}
        ref={ref}
      >
        <ConditionalWrapper
          condition={!!contentType.contentGroups.length}
          wrapper={(children) => (
            <ContentGroupTabsInComponent
              contentComponentId={contentComponent.id}
              contentType={contentType}
            >
              <Box paddingTop={Spacing.L}>
                <TabView.TabGroup />
              </Box>
              {children}
            </ContentGroupTabsInComponent>
          )}
        >
          {expandedContentComponentElements}
        </ConditionalWrapper>
      </div>
    );
  },
);

ExpandedContentComponentElements.displayName = 'ExpandedContentComponentElements';
